<template>
  <div class="table-row">
    <p class="table-row__id" v-if="showId">{{ data.id }}</p>
    <div class="table-row__sort" v-if="sort">
      <p v-if="!isEdit">{{ data.ord }}</p>
      <ui-input v-if="isEdit" v-model="editOrdValue" ref="field" />
    </div>
    <div class="table-row__sort" v-if="isFinal || isCode">
      <p v-if="!isEdit">{{ data.code }}</p>
      <ui-input v-if="isEdit" v-model="editCodeValue" ref="field" />
    </div>
    <div class="table-row__item">
      <p v-if="!isEdit">{{ data.name }}</p>
      <ui-input v-if="!isDistrictRegion && isEdit && columnNumInfo?.valuesClass !== 'Region'" v-model="editValue" ref="field" />
      <div class="multiselect" v-if="isEdit && columnNumInfo?.valuesClass === 'Region' || isEdit && isDistrictRegion">
        <Multiselect
            @search-change="getCityApi"
            id="multiSelect"
            v-model="regionVal"
            :options="regionsOptions"
            placeholder=""
            :loading="isLoadingMultiSelect"
            :custom-label="getCityName"
            :close-on-select="true"
            :show-labels="false"
        >
          <template slot="noOptions" >
            {{ $t('enter-address') }}
          </template>
          <span slot="noResult">
            {{ $t('not-found') }}
          </span>
        </Multiselect>
      </div>
    </div>
    <div class="table-row__international" v-if="internationalName">
      <p v-if="!isEdit">{{ data?.internationalName }}</p>
      <ui-input v-if="isEdit" v-model="internationalNameVal" ref="field" />
    </div>
    <div class="table-row__ratio" v-if="isRatio">
      <p v-if="!isEdit && getDataRatio">{{ getDataRatio }}%</p>
      <ui-input v-if="isEdit" v-model="editRatio" ref="field" />
    </div>
    <div class="table-row__ratio" v-if="shortName">
      <p v-if="!isEdit">{{ data?.shortName }}</p>
      <ui-input v-if="isEdit" v-model="editShortName" ref="field" />
    </div>
    <div class="table-row__base" v-if="isBase">
      <p v-if="!isEdit">{{ data.measureName }}</p>
      <ui-input placeholder="..." v-if="isEdit" v-model="measureValue" />
    </div>
    <div v-if="isBase" class="table-row__base-select">
      <UiSelect v-model="typeField" :options="typeFieldOptions" @input="changeBaseField"/>
    </div>
    <div v-if="periods" class="table-row__periods-select">
      <UiSelect v-model="isPublic" :options="isPublicOptions" @input="changePeriodsInfo"/>
    </div>
    <template v-if="isFinal">
      <div class="table-row__final-select-first table-row__final-select">
        <UiSelect v-model="federalDistrict" :options="federalDistrictOptions" @input="changeFinalField"/>
      </div>
      <div class="table-row__final-ratio">
        <p v-if="!isEdit && getDataRatio">{{ getDataRatio }}%</p>
        <ui-input placeholder="..." v-if="isEdit" v-model="editRatio" />
      </div>
      <div class="table-row__final-select-second table-row__final-select">
        <UiSelect v-model="algorithmId" :options="getAlgorithms" @input="changeFinalField"/>
      </div>
      <div class="table-row__final-select-third table-row__final-select">
        <UiSelect v-model="inversion" :options="inversionOptions" @input="changeFinalField"/>
      </div>
    </template>
    <template v-if="isAlgorithm">
      <div class="table-row__algorithm">
        <p v-if="!isEdit">{{ getAlgorithmNumber }}</p>
        <ui-input placeholder="..." v-if="isEdit" v-model="editAlgorithmNumber" />
      </div>
      <div class="table-row__algorithm-select">
        <UiSelect v-model="algorithmInversion" :options="algorithmInversionOptions" @input="changeAlgorithmInversion"/>
      </div>
    </template>
    <div v-if="showSelect" class="table-row__select">
      <UiSelect @input="changeType" v-model="selectModel" :options="options" />
    </div>
    <div class="table-row__attention" v-if="attention">
      <ui-tooltip color="grey" alert @updateDescription="updateDescription($event)" :data="data" :isShow="data.description">
        <h2 class="title">{{ data.description}}</h2>
      </ui-tooltip>
    </div>
    <div class="table-row__columns" v-for="(el, key) in newColumn" v-show="columnsCount" :key="key">
      <div v-for="(item, key) in el.numbers" :key="key">
        <table-column-number-edit :item="item" v-if="data.id === item.orderSubtypeId || data.id === item.specValueId" @editColumnNumber="editColumnNumber({column: el, number: item, row: data})"/>
      </div>
      <table-column-number @addNewNumber="$emit('addNewNumber', $event)" v-if="el.numbers.every(val =>val.orderSubtypeId !== data.id && val.specValueId !== data.id)" :column="el" :row="data" :data="columnNumInfo"/>
    </div>
    <div v-if="showNewColumn" class="table-row__new-column"></div>
    <div class="table-row__checkbox" v-if="isCheckbox">
      <UiCheckbox v-model="isRequired" @input="changeType"/>
    </div>
    <div class="table-row__checkbox second" v-if="isCheckbox">
      <UiCheckbox v-model="showUnknownMark" @input="changeType"/>
    </div>
    <div v-if="isGlobeIcon" class="table-row__center" :style="{maxWidth: getIconsMaxWidth}">
      <img src="@/assets/svg/admin/globe.svg" alt="" @click="showLanguageAlert = true">
    </div>
    <div class="table-row__center" :style="{maxWidth: getIconsMaxWidth}">
      <img
          v-if="!isEdit"
          @click="isEdit = !isEdit"
          src="@/assets/svg/admin/edit-default.svg"
          alt=""
      >
      <img
          v-if="(isEdit && editValue === data.name && editOrdValue === data.ord) || (isEdit && !editValue)"
          @click="isEdit = !isEdit"
          src="@/assets/images/check-disable.png"
          alt=""
      >
      <img
          v-if="isEdit && editValue && (editValue !== data.name || editOrdValue !== data.ord)"
          @click="editItem"
          src="@/assets/images/check-active.png"
          alt=""
      >
    </div>
    <div v-if="!hideDeleteIcon" class="table-row__center" :style="{maxWidth: getIconsMaxWidth}">
      <img @click="showAlert = true" src="@/assets/svg/admin/table-delete-icon.svg" alt="">
    </div>

    <UiAlert v-model="showAlert">
      <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="deleteItem" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>

    <UiAlert v-model="showLanguageAlert" class="language-alert" :show-close="true">
      <div class="language-alert__title">{{ data.name }}</div>
      <div class="language-alert__form">
        <UiInput label="English" v-model="nameIntEn" />
        <UiInput label="English short name" v-model="shortNameIntEn" v-if="shortName"/>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="saveIntNames">{{ $t('save') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiAlert from "@/components/ui/UiAlert";
import UiButton from "@/components/ui/UiButton";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import order from "@/api/module/order";
import TableColumnNumber from "@/components/common/admin/TableColumnNumber.vue";
import TableColumnNumberEdit from "@/components/common/admin/TableColumnNumberEdit.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import UiCheckbox from "@/components/ui/UiCheckbox.vue";

export default {
  name: "AdminTableRow",
  computed: {
    ...mapGetters(['getAlgorithms']),
    order() {
      return order
    },

    getDataRatio () {
      return this.data?.ratio ? this.data.ratio.toString().replace('.', ',') : ''
    },

    getAlgorithmNumber () {
      return this.data?.parmNumber ? this.data.parmNumber.toString().replace('.', ',') : ''
    },

    getIconsMaxWidth () {
      if (this.isBase || this.isAlgorithm) {
        return '80px'
      } else if (this.isFinal) {
        return '50px'
      } else if (this.columnsCount > 1 || (this.showNewColumn && this.columnsCount === 1)) {
        if(this.isGlobeIcon) {
          return '5%'
        } else {
          return '7%'
        }
      } else if(!this.sort && (this.columnsCount === 0 || this.columnsCount === 1)) {
        return '105px'
      } else if (!this.sort) {
        return '162px'
      } else {
        if(this.isGlobeIcon) {
          return '85px'
        } else {
          return '130px'
        }
      }
    }
  },
  components: {
    UiCheckbox,
    Multiselect,
    TableColumnNumberEdit,
    TableColumnNumber,
    UiTooltip,
    UiButton,
    UiAlert,
    UiInput: () => import('@/components/ui/UiInput'),
    UiSelect: () => import('@/components/ui/UiSelect')
  },

  props: {
    attention: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    hideDeleteIcon: {
      type: Boolean,
      default: false
    },
    showNewColumn: {
      type: Boolean,
      default: false,
    },
    newColumn: {
      type: [],
      default: () => []
    },
    columnsCount: {
      type: Number,
      default: 0
    },
    columnNumInfo: {
      type: [Array, Object],
      default: () => {}
    },
    isRatio: {
      type: Boolean,
      default: false
    },
    isDistrictRegion: {
      type: Boolean,
      default: false
    },
    isBase: {
      type: Boolean,
      default: false
    },
    isFinal: {
      type: Boolean,
      default: false
    },
    isAlgorithm: {
      type: Boolean,
      default: false
    },
    shortName: {
      type: Boolean,
      default: false
    },
    periods: {
      type: Boolean,
      default: false
    },
    isCheckbox: {
      type: Boolean,
      default: false
    },
    isGlobeIcon: {
      type: Boolean,
      default: false
    },
    isCode: {
      type: Boolean,
      default: false
    },
    showId: {
      type: Boolean,
      default: true
    },
    internationalName: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isEdit: false,
      showAlert: false,
      options: [
        { name: this.$t('integer'), value: 'Integer' },
        { name: this.$t('fractional-number'), value: 'Decimal' },
        { name: this.$t('list'), value: 'Key' },
      ],
      form: [
        {
          columnVal: '1'
        },
        {
          columnVal: '2'
        },
        {
          columnVal: '3'
        },
      ],
      selectModel: {},
      editValue: '',
      editOrdValue: '',
      editRatio: '',
      emptyColumnNum: [],
      isRegion: false,
      regionVal: {},
      regionsOptions: [],
      isLoadingMultiSelect: false,
      measureValue: '',
      typeFieldOptions: [
        { name: this.$t('integer'), value: 'Integer' },
        { name: this.$t('fractional-number'), value: 'Decimal' },
        { name: this.$t('yes-no'), value: 'Boolean' },
      ],
      typeField: null,
      editCodeValue: '',
      federalDistrict: {},
      federalDistrictOptions: [
        {name: this.$t('federal-district'), value: true},
        {name: this.$t('russian-federation'), value: false},
        {name: this.$t('no'), value: null}
      ],
      algorithmId: {},
      algorithmIdOptions: [
        // {name: 'Место в рейтинге, 2 параметра', id: 1},
        {name: this.$t('weighted-subtype'), id: 2},
        // {name: 'Место в рейтинге, 1 параметра', id: 3},
      ],
      inversion: {},
      inversionOptions: [
        {name: this.$t('yes'), value: true},
        {name: this.$t('no'), value: false}
      ],
      editAlgorithmNumber: '',
      algorithmInversion: {},
      algorithmInversionOptions: [
        {name: this.$t('yes'), value: true},
        {name: this.$t('no'), value: false}
      ],
      editShortName: '',
      isPublic: {},
      isPublicOptions: [
        {name: this.$t('yes'), value: true},
        {name: this.$t('no'), value: false}
      ],
      isRequired: false,
      showUnknownMark: false,
      nameIntEn: '',
      shortNameIntEn: '',
      showLanguageAlert: false,
      internationalNameVal: ''
    }
  },

  methods: {
    ...mapActions(['deleteType', 'subtypes', "editType", 'regions', 'algorithms']),
    editItem() {
        let data = {
          id: this.data.id,
          name: this.columnNumInfo?.valuesClass === 'Region' ? this.regionVal.name : this.$data.editValue,
          ord: this.editOrdValue,
          typeId: this.data.typeId,
          dataType: this.selectModel?.value,
          valuesClass: this.columnNumInfo?.valuesClass,
          ratio: +this.editRatio.toString().replace(',', '.'),
          regionVal: this.regionVal,
          measureName: this.measureValue,
          baseTypeField: this.typeField?.value,
          code: this.editCodeValue,
          algorithmId: this.algorithmId?.id,
          isInversion: this.inversion?.value,
          isFederalDistrictBased: this.federalDistrict?.value,
          params: this.data.params,
          algorithmNumber: +this.editAlgorithmNumber.toString().replace(',', '.'),
          algorithmInversion: this.algorithmInversion?.value,
          shortName: this.editShortName,
          isPublic: this.isPublic?.value,
          isRequired: this.isRequired,
          showUnknownMark: this.showUnknownMark,
          nameIntEn: this.data.nameInt?.en,
          shortNameIntEn: this.data?.shortNameInt?.en,
          description: this.data?.description,
          descriptionEn: this.data?.descriptionInt?.en,
          internationalName: this?.internationalNameVal
        };
        this.$emit('changeRowField', data);
        this.isEdit = !this.isEdit;
    },

    deleteItem() {
      document.querySelector('.header').style.position = 'fixed'
      this.showAlert = false
      let data = {
        id: this.data.id,
        subtype: this.data.typeId,
        valuesClass: this.columnNumInfo?.valuesClass
      }
      this.$emit('deleteRowField', data)
    },

    changeType () {
      let info = {
        name: this.data.name,
        id: this.data.id,
        type: this.selectModel.name,
        ord: this.data.ord
      }
      this.$emit('changeType', info);

      let data = {
        id: this.data.id,
        name: this.data.name,
        typeId: this.data.typeId,
        dataType: this.selectModel?.value,
        ord: this.data.ord,
        valuesClass: this.columnNumInfo?.valuesClass,
        isRequired: this.isRequired,
        showUnknownMark: this.showUnknownMark
      };
      this.$emit('changeRowField', data);
    },

    changeBaseField () {
      let data = {
        id: this.data.id,
        name: this.data.name,
        ord: this.data.ord,
        measureName: this.data.measureValue,
        baseTypeField: this.typeField.value
      };
      this.$emit('changeRowField', data);
    },

    changePeriodsInfo () {
      let data = {
        id: this.data.id,
        ord: this.data.ord,
        isPublic: this.isPublic.value
      };
      this.$emit('changeRowField', data);
    },

    changeFinalField () {
      let data = {
        id: this.data.id,
        name: this.data.name,
        ord: this.data.ord,
        code: this.data.code,
        algorithmId: this.algorithmId.id,
        isInversion: this.inversion.value,
        isFederalDistrictBased: this.federalDistrict.value,
        params: this.data.params
      };
      this.$emit('changeRowField', data);
    },

    showEditColumnNumber(data) {
      this.$emit('showEditColumnNumber', data)
    },

    editColumnNumber(data) {
      this.$emit('editColumnNumber', data)
    },

    updateDescription(value) {
      let data = {
        description: value.description,
        descriptionEn: value.descriptionEn,
        id: this.data.id,
        name: this.data.name,
        ord: this.data.ord,
        typeId: this.data.typeId,
        dataType: this.selectModel?.value,
        nameIntEn: this.data.nameInt?.en,
      }
      this.$emit('updateDescription', data)
    },

    getCityApi(query) {
      if (!query) return
      this.regionsOptions = []
      this.isLoadingMultiSelect = true
      if(this.isDistrictRegion) {
        this.regions({
          skip: 0,
          take: 200,
        }).then((res) => {
          this.regionsOptions = res.data
          this.isLoadingMultiSelect = false
        })
      } else {
        axios.get(`https://geocode-maps.yandex.ru/1.x?apikey=bedfc36e-e123-4c97-951b-4de2553423b2&format=json&lang=ru_RU&results=50&locality=kind&geocode=${query}`)
            .then((res) => {
              res.data.response.GeoObjectCollection.featureMember.forEach((val, idx) => {
                const { Address, kind } = val.GeoObject.metaDataProperty.GeocoderMetaData
                if(Address.country_code === 'RU' && kind === 'province') {
                  const obj = {
                    id: idx + 1,
                    name: val.GeoObject.name
                  }
                  this.regionsOptions.push(obj)
                }
              })
              this.isLoadingMultiSelect = false
            })
            .catch(() => {
              this.isLoadingMultiSelect = false
            })
      }
    },

    getCityName({ name }) {
      return name
    },

    changeAlgorithmInversion () {
      let data = {
        id: this.data.id,
        name: this.data.name,
        algorithmNumber: this.data.parmNumber,
        algorithmInversion: this.algorithmInversion.value
      }
      this.$emit('changeRowField', data)
    },

    saveIntNames () {
      let data = {
        id: this.data.id,
        name: this.data.name,
        ord: this.data.ord,
        typeId: this.data.typeId,
        dataType: this.data.dataType,
        valuesClass: this.data.valuesClass,
        ratio: this.data.ratio,
        regionVal: this.data.regionVal,
        measureName: this.data.regionVal,
        baseTypeField: this.data.baseTypeField,
        code: this.data.code,
        algorithmId: this.data.algorithmId,
        isInversion: this.data.isInversion,
        isFederalDistrictBased: this.data.isFederalDistrictBased,
        params: this.data.params,
        algorithmNumber: this.data.algorithmNumber,
        algorithmInversion: this.data.algorithmInversion,
        shortName: this.data.shortName,
        isPublic: this.data.isPublic,
        isRequired: this.data.isRequired,
        showUnknownMark: this.data.showUnknownMark,
        nameIntEn: this.nameIntEn,
        shortNameIntEn: this.shortNameIntEn,
        description: this.data.description,
        descriptionEn: this.data.descriptionInt.en
      };
      this.$emit('changeRowField', data)
    }
  },

  watch: {
    isEdit(val) {
      if(val) {
        this.editValue = this.data.name
        this.regionVal.name = this.data.name
        this.editOrdValue = this.data.ord ? this.data.ord.toString() : ''
        this.editRatio = this.data.ratio ? this.data.ratio.toString().replace('.', ',') : ''
        this.measureValue = this.data.measureName ? this.data.measureName : ''
        this.editCodeValue = this.data.code ? this.data.code : ''
        this.editAlgorithmNumber = this.data.parmNumber ? this.data.parmNumber.toString().replace('.', ',') : ''
        this.editShortName = this.data?.shortName
        this.internationalNameVal = this.data?.internationalName
        setTimeout(() => {
          document.querySelector('.table-row__item input').focus()
        }, 100)
      }
    },

    showLanguageAlert() {
      this.nameIntEn = this.data.nameInt?.en || ''
      this.shortNameIntEn = this.data.shortNameInt?.en || ''
    }
  },

  mounted() {
    this.selectModel = this.options.find((option) => {
      return this.data.dataType === option?.value
    });

    let info = {
      name: this.data?.name,
      id: this.data?.id,
      type: this.selectModel?.name
    }
    this.$emit('changeType', info);

    this.typeField = this.typeFieldOptions.find((option) => {
      return this.data.dataType === option?.value
    });

    this.federalDistrict = this.federalDistrictOptions.find((option) => {
      return this.data.isFederalDistrictBased === option?.value
    });

    if(this.isFinal) {
      this.algorithms().then(() => {
        this.algorithmId = this.getAlgorithms.find((option) => {
          return this.data.algorithmId === option?.id
        });
      })
    }

    this.inversion = this.inversionOptions.find((option) => {
      return this.data.isInversion === option?.value
    });

    this.algorithmInversion = this.algorithmInversionOptions.find((option) => {
      return this.data.canInversion === option?.value
    });

    this.isPublic = this.isPublicOptions.find((option) => {
      return this.data.isPublic === option?.value
    });

    this.isRequired = this.data?.isRequired

    this.showUnknownMark = this.data?.showUnknownMark

    this.nameIntEn = this.data.nameInt?.en || ''
    this.shortNameIntEn = this.data.shortNameInt?.en || ''
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
 .table-row {
   display: flex;

   &__sort {
     display: flex;
     align-items: center;
     justify-content: center;
     max-width: 62px;
     width: 100%;
     font-size: 14px;
     font-family: 'Gotham Pro regular', sans-serif;
     font-weight: 400;
     line-height: 120.2%;
     color: #1B1A1F;
     border: 1px solid #F5F5F5;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__id {
     display: flex;
     align-items: center;
     justify-content: center;
     max-width: 62px;
     width: 100%;
     font-size: 14px;
     font-family: 'Gotham Pro regular', sans-serif;
     font-weight: 400;
     line-height: 120.2%;
     color: #1B1A1F;
     border: 1px solid #F5F5F5;
   }

   &__item {
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 10px 20px;
     border: 1px solid #F5F5F5;
     width: 100%;
     min-height: 40px;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__ratio {
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 10px 20px;
     border: 1px solid #F5F5F5;
     width: 100%;
     max-width: 256px;
     min-height: 40px;
     text-align: center;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__international {
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 10px 20px;
     border: 1px solid #F5F5F5;
     width: 100%;
     max-width: 500px;
     min-height: 40px;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__base {
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 10px 20px;
     border: 1px solid #F5F5F5;
     width: 100%;
     max-width: 160px;
     min-height: 40px;
     text-align: center;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__base-select {
     display: flex;
     align-items: center;
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 0 20px;
     border: 1px solid #F5F5F5;
     width: 100%;
     max-width: 160px;
     min-height: 40px;

     ::v-deep .ui-select__inner {
       border: none;
       padding: 0;

       &-text {
         white-space: nowrap;
       }
     }

     ::v-deep .ui-select__label {
       display: none;
     }

     ::v-deep .ui-select {
       z-index: auto;
     }

     ::v-deep .ui-select__options {
       top: 40px;
       text-align: left;
       width: 160px;
     }
   }

   &__periods-select {
     display: flex;
     align-items: center;
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 0 20px;
     border: 1px solid #F5F5F5;
     width: 100%;
     max-width: 200px;
     min-height: 40px;

     ::v-deep .ui-select__inner {
       border: none;
       padding: 0;

       &-text {
         white-space: nowrap;
       }
     }

     ::v-deep .ui-select__label {
       display: none;
     }

     ::v-deep .ui-select {
       z-index: auto;
     }

     ::v-deep .ui-select__options {
       top: 40px;
       text-align: left;
       width: 160px;
     }
   }

   &__final-ratio {
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 10px 5px;
     border: 1px solid #F5F5F5;
     width: 100%;
     max-width: 111px;
     min-height: 40px;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__final-select {
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 0 10px;
     border: 1px solid #F5F5F5;
     width: 100%;
     min-height: 40px;

     ::v-deep .ui-select__inner {
       border: none;
       padding: 0;
     }

     ::v-deep .ui-select__label {
       display: none;
     }

     ::v-deep .ui-select {
       z-index: auto;
     }

     ::v-deep .ui-select__options {
       top: 40px;
       left: -30px;
       text-align: left;
       width: 160px;
     }

     &-first {
       max-width: 97px;
     }

     &-second {
       max-width: 120px;
     }

     &-third {
       max-width: 100px;
     }
   }

   &__algorithm {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     max-width: 180px;
     border: 1px solid #F5F5F5;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__algorithm-select {
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 14px;
     line-height: 120.2%;
     color: #1B1A1F;
     padding: 0 10px;
     border: 1px solid #F5F5F5;
     width: 100%;
     min-height: 40px;
     max-width: 200px;

     ::v-deep .ui-select__inner {
       border: none;
       padding: 0;
     }

     ::v-deep .ui-select__label {
       display: none;
     }

     ::v-deep .ui-select {
       z-index: auto;
     }

     ::v-deep .ui-select__options {
       top: 40px;
       text-align: left;
       width: 160px;
     }
   }

   &__new-column {
     display: flex;
     align-items: center;
     width: 100%;
     max-width: 180px;
     border: 1px solid #F5F5F5;
   }

   &__columns {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     max-width: 180px;
     border: 1px solid #F5F5F5;

     ::v-deep .ui-input {
       input {
         border: none;
         padding: 5px 30px 5px 3px;
         height: auto;
       }
     }
   }

   &__select {
     display: flex;
     align-items: center;
     width: 100%;
     max-width: 200px;
     border: 1px solid #F5F5F5;

     ::v-deep .ui-select__inner {
       border: none;
     }
     ::v-deep .ui-select__label {
       display: none;
     }

     ::v-deep .ui-select {
       z-index: auto;
     }

     ::v-deep .ui-select__options {
       top: 40px;
     }
   }

   &__center {
     display: flex;
     justify-content: center;
     align-items: center;
     border: 1px solid #F5F5F5;
     max-width: 130px;
     width: 100%;

     img {
       width: 16px;
       height: 16px;
       cursor: pointer;
     }
   }

   &__attention {
     display: flex;
     justify-content: center;
     align-items: center;
     border: 1px solid #F5F5F5;
     max-width: 130px;
     width: 100%;

     img {
       width: 16px;
       height: 16px;
       cursor: pointer;
     }
   }

   &__checkbox {
     display: flex;
     justify-content: center;
     align-items: center;
     border: 1px solid #F5F5F5;
     max-width: 170px;
     width: 100%;

     &.second {
       max-width: 100px !important;
     }
   }
 }
 ::v-deep .tooltip__window {
   max-width: 150px;
 }
 .title{
   color: #1B1A1F;
   font-size: 14px;
 }

 .text {
   color: #1B1A1F;
   font-size: 14px;
   line-height: 120.2%;
   font-family: "Gotham Pro Regular";
 }
 .multiselect {
   display: flex;
   flex-direction: column;
   gap: 10px;
 }
 ::v-deep .multiselect__tags {
   border-radius: 25px !important;
   max-height: 198px !important;
 }
 ::v-deep .multiselect__option {
   font-size: 12px !important;
   font-family: "Gotham Pro Regular";
 }
 ::v-deep .multiselect__content-wrapper {
   border-radius: 10px;
 }
 ::v-deep .multiselect__single {
   font-size: 12px;
   font-family: "Gotham Pro Regular";
 }
 ::v-deep .multiselect__option--selected.multiselect__option--highlight {
   background: none !important;
 }
 ::v-deep .multiselect__option--selected {
   color: #E21F1F !important;
 }
 ::v-deep .multiselect__option--highlight {
   background: none !important;
   color: #E21F1F !important;
 }
 ::v-deep .multiselect__input {
   font-size: 12px !important;
   font-family: "Gotham Pro Regular";
 }
 ::v-deep .multiselect__spinner {
   border-radius: 28px;
 }
 ::v-deep .multiselect--above {
   .multiselect__content-wrapper {
     bottom: 100% !important;
     top: auto !important;
   }
 }

 ::v-deep .multiselect__content-wrapper {
   top: 100% !important;
 }

 .language-alert {
   ::v-deep .ui-alert {
     background: #FFFFFF;
     padding: 25px;
     max-width: 300px;
   }

   &__title {
     text-align: left;
     width: 100%;
     border-bottom: 2px solid #F5F5F5;
     padding-bottom: 21px;
   }

   &__form {
     width: 100%;
     display: flex;
     flex-direction: column;
     gap: 10px;
   }
 }
</style>
